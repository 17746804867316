<template>
    <div>
        <el-dialog v-model="dialogVisible" width="600">
            <div>
                <div class="Popup_top">
                    <div class="Popup_top_item" :class="LoginType == 1 ? 'Popup_top_select' : ''" @click="LoginType = 1">登录</div>
                    <div class="Popup_top_item" :class="LoginType == 2 ? 'Popup_top_select' : ''" @click="LoginType = 2">注册</div>
                </div>
                <el-form label-width="70px" class="fromBox" v-if="LoginType == 1">
                    <el-form-item label="账号">
                        <el-input />
                    </el-form-item>
                    <el-form-item label="密码">
                        <el-input />
                    </el-form-item>
                    <div class="Submit_Info">
                        <el-button type="primary">确定</el-button>
                        <el-button @click="dialogVisible = false">取消</el-button>
                    </div>
                </el-form>
                <el-form label-width="70px" class="fromBox" v-if="LoginType == 2">
                    <el-form-item label="账号">
                        <el-input />
                    </el-form-item>
                    <el-form-item label="验证码">
                        <div style="display: flex;align-items: center;">
                            <el-input style="flex: 1;"/>
                            <el-button type="primary" style="margin-left: 20px;">获取验证码</el-button>
                        </div>
                    </el-form-item>
                    <el-form-item label="输入密码">
                        <el-input />
                    </el-form-item>
                    <el-form-item label="确定密码">
                        <el-input />
                    </el-form-item>
                    <div style="padding: 10px 0px;">请阅读<span @click="JumpAgreement" style="color: rgb(30, 148, 255);cursor: pointer;">《用户注册协议》</span>和<span @click="jumpprivacyMain" style="color: rgb(30, 148, 255);cursor: pointer;">《隐私政策》</span></div>
                    <div class="Submit_Info">
                        <el-button type="primary">确定</el-button>
                        <el-button @click="dialogVisible = false">取消</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { ref } from "vue"
import { useRouter } from 'vue-router'
export default {
    setup(){
        const router = useRouter();
        let dialogVisible = ref(false)
        let LoginType = ref(1)

        function InitdialogVisible(){
            dialogVisible.value = !dialogVisible.value
        }
        function JumpAgreement(){
            dialogVisible.value = false
            router.push(`/Agreement`);
        }
        function jumpprivacyMain(){
            dialogVisible.value = false
            router.push(`/privacyMain`);
        }

        return{
            dialogVisible,
            LoginType,
            InitdialogVisible,
            JumpAgreement,
            jumpprivacyMain
        }
    }
}
</script>

<style scoped>
.Popup_top{
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ccc;
}
.Popup_top_item{
    flex: 1;
    text-align: center;
    font-size: 15px;
    padding-bottom: 10px;
    cursor: pointer;
}
.Popup_top_select{
    color: rgb(30, 148, 255);
}
.fromBox{
    width: 300px;
    margin: 20px auto;
}
.Submit_Info{
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
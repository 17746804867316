<template>
    <div>
        <Banner></Banner>
        <div class="Main">
            <div class="Main_title">服务信息</div>
            <div class="goodsList">
                <div class="goodsList_item"  @click="GogoodsDetails(1,'有省科技（广东）有限公司')">
                    <img class="goodsList_item_image" src="../assets/91/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">贷款项目</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:有省科技（广东）有限公司</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(2,'有省科技（广东）有限公司')">
                    <img class="goodsList_item_image" src="../assets/U+/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">家具项目</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:有省科技（广东）有限公司</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(3,'柚米（广东）互联网科技有限公司')">
                    <img class="goodsList_item_image" src="../assets/WE/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">销售平台</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:柚米（广东）互联网科技有限公司</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(4,'柚米（广东）互联网科技有限公司')">
                    <img class="goodsList_item_image" src="../assets/Charging/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">充电桩查询</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:柚米（广东）互联网科技有限公司</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(5,'柚米（广东）互联网科技有限公司')">
                    <img class="goodsList_item_image" src="../assets/ClothingERP/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">服装ERP管理系统</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:柚米（广东）互联网科技有限公司</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(6,'柚米（广东）互联网科技有限公司')">
                    <img class="goodsList_item_image" src="../assets/Official/cover.png"/>
                    <div class="goodsList_item_text">
                        <div class="title">餐饮行业官网</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:柚米（广东）互联网科技有限公司</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(7,'柚米（广东）互联网科技有限公司')">
                    <img class="goodsList_item_image" src="../assets/Supplier/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">采购平台</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:柚米（广东）互联网科技有限公司</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(8,'有省科技（广东）有限公司')">
                    <img class="goodsList_item_image" src="../assets/freshproduce/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">生鲜平台</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:有省科技（广东）有限公司</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(9,'柚米（广东）互联网科技有限公司')">
                    <img class="goodsList_item_image" src="../assets/Craftsmanship/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">全屋定制家具</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:柚米（广东）互联网科技有限公司</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(10,'有省科技（广东）有限公司')">
                    <img class="goodsList_item_image" src="../assets/JialiRen/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">美容行业平台</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:有省科技（广东）有限公司</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(11,'有省科技（广东）有限公司')">
                    <img class="goodsList_item_image" src="../assets/Homelife/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">家居生活</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:有省科技（广东）有限公司</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(12,'柚米（广东）互联网科技有限公司')">
                    <img class="goodsList_item_image" src="../assets/Whale/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">选房平台</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:柚米（广东）互联网科技有限公司</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(13,'有省科技（广东）有限公司')">
                    <img class="goodsList_item_image" src="../assets/Neighbor/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">电商带货</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:有省科技（广东）有限公司</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(14,'有省科技（广东）有限公司')">
                    <img class="goodsList_item_image" src="../assets/Maternal/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">母婴平台</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:有省科技（广东）有限公司</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(15,'柚米（广东）互联网科技有限公司')">
                    <img class="goodsList_item_image" src="../assets/Timber/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">木材货源</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:柚米（广东）互联网科技有限公司</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(16,'宁乡市昭辰信息咨询服务部')">
                    <img class="goodsList_item_image" src="../assets/Platform/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">社交平台</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:宁乡市昭辰信息咨询服务部</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(17,'宁乡市昭辰信息咨询服务部')">
                    <img class="goodsList_item_image" src="../assets/Community/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">微宠平台</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:宁乡市昭辰信息咨询服务部</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(18,'宁乡市昭辰信息咨询服务部')">
                    <img class="goodsList_item_image" src="../assets/Microfinance/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">理财项目</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:宁乡市昭辰信息咨询服务部</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(19,'宁乡市昭辰信息咨询服务部')">
                    <img class="goodsList_item_image" src="../assets/ornaments/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">手游项目</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:宁乡市昭辰信息咨询服务部</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(20,'宁乡市昭辰信息咨询服务部')">
                    <img class="goodsList_item_image" src="../assets/Encounter/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">二手交易</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:宁乡市昭辰信息咨询服务部</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(21,'宁乡市昭辰信息咨询服务部')">
                    <img class="goodsList_item_image" src="../assets/Idlefriends/Idlefriends1.jpg" />
                    <div class="goodsList_item_text">
                        <div class="title">校友服务</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:宁乡市昭辰信息咨询服务部</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(22,'宁乡市昭辰信息咨询服务部')">
                    <img class="goodsList_item_image" src="../assets/Yafu/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">购物app</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:宁乡市昭辰信息咨询服务部</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(23,'宁乡市昭辰信息咨询服务部')">
                    <img class="goodsList_item_image" src="../assets/Medical/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">医疗器械项目</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:宁乡市昭辰信息咨询服务部</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(24,'广州市天河区沙东凯仕信息技术服务部')">
                    <img class="goodsList_item_image" src="../assets/marketing/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">营销管理系统</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:广州市天河区沙东凯仕信息技术服务部</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(25,'广州市天河区沙东凯仕信息技术服务部')">
                    <img class="goodsList_item_image" src="../assets/Zhongtian/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">手机数码</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:广州市天河区沙东凯仕信息技术服务部</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(26,'广州市天河区沙东凯仕信息技术服务部')">
                    <img class="goodsList_item_image" src="../assets/Music/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">音乐平台</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:广州市天河区沙东凯仕信息技术服务部</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(27,'广州市天河区沙东凯仕信息技术服务部')">
                    <img class="goodsList_item_image" src="../assets/meeting/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">会议系统</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:广州市天河区沙东凯仕信息技术服务部</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(28,'广州市天河区沙东凯仕信息技术服务部')">
                    <img class="goodsList_item_image" src="../assets/Enterprise/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">人脉项目</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:广州市天河区沙东凯仕信息技术服务部</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(29,'广州市天河区沙东凯仕信息技术服务部')">
                    <img class="goodsList_item_image" src="../assets/liuxuesheng/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">学生管理</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:广州市天河区沙东凯仕信息技术服务部</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(30,'广州市天河区沙东凯仕信息技术服务部')">
                    <img class="goodsList_item_image" src="../assets/crown/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">设计项目</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:广州市天河区沙东凯仕信息技术服务部</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(31,'广州骅穗教育咨询有限公司')">
                    <img class="goodsList_item_image" src="../assets/curriculum/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">课程管理平台</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:广州骅穗教育咨询有限公司</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
                <div class="goodsList_item"  @click="GogoodsDetails(32,'广州骅穗教育咨询有限公司')">
                    <img class="goodsList_item_image" src="../assets/train/cover.png" />
                    <div class="goodsList_item_text">
                        <div class="title">培训管理服务支撑系统</div>
                        <div class="Time">
                            <div class="Time_left">发布用户:广州骅穗教育咨询有限公司</div>
                            <div style="color: red;">立即咨询</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted } from 'vue'
import Banner from '@/components/Banner.vue';
import { useRouter } from 'vue-router'
export default {
    components:{
        Banner
    },
    setup(){
        onMounted(() => {
            document.title = "服务信息列表"
        });

        const router = useRouter();
        function GoarticleDetails(e){
            router.push(`/articleDetails/${e}`);
        }
        
        function GogoodsDetails(e,name){
            router.push({
                name:'goodsDetails',
                params:{
                    id:e,
                    name:name
                }
            })
        }
        return{
            GoarticleDetails,
            GogoodsDetails,
        }
    }
}
</script>

<style scoped>
.Main{
    width: 1200px;
    margin: 0 auto;
    
}
.Main_title{
    font-size: 30px;
    text-align: center;
    padding: 40px 0px;
    font-weight: 600;
}
.Main_top{
    display: flex;
    align-content: center;
}
.Main_top_image{
    width: 300px;
    height: 150px;
}
.Main_top_right{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 30px;
    cursor: pointer;
}
.Main_top_right_title{
    font-size: 20px;
}
.Main_top_right_desc{
    font-size: 18px;
    margin-top: 10px;
    overflow:hidden; 
    text-overflow:ellipsis;
    display:-webkit-box; 
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2; 
}
.Main_top_right_time{
    color: rgb(153, 153, 153);
    font-size: 16px;
}
.Main_list{
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0px;
}
.Main_list_item{
    width: calc(50% - 40px);
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}
.Main_list_item:nth-child(even){
    margin-left: 40px;
}

.Main_list_item_name{
    font-size: 13px;
    color: #000;
}
.Main_list_item_time{
    color: rgb(153, 153, 153);
    font-size: 11px;
}

.goodsList{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
}
.goodsList_item{
    width:calc(20% - 10px);
    margin-right:10px;
    margin-top: 20px;
    cursor: pointer;
}
.goodsList_item_image{
    width: 100%;
    height: 150px;
    display: block;
    border-radius: 5px 5px 0 0;
}
.goodsList_item_text{
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .2);
    padding: 10px;
}
.title{
    font-size: 15px;
    overflow:hidden; 
    text-overflow:ellipsis;
    display:-webkit-box; 
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
}
.Time{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 12px;
    color: rgb(153, 153, 153);
}

.Time_left{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
}
</style>
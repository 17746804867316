<template>
  <div class="banner">
    <el-carousel style="width: 100%; height: 100%">
      <el-carousel-item>
        <img
          class="BannerImage"
          src="../assets/banner_Icon.png"
        />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<style scoped>
.banner {
  width: 100%;
  height: 681px;
}
:deep(.el-carousel__container) {
  height: 100%;
}
.BannerImage{
  width: 100%;
  height: 920px;
}
</style>

<template>
  <div class="Info">
    <h1>在线协议</h1>
    <div>
      用户服务协议 更新日期:2024年3月13日 生效日期:2024年3月13日 特别提示
      欢迎使用腾博会官网为您提供的平台。请您务必审慎阅读、充分理解以下内容，特别是免除或者限制腾博会官网责任的条款、对用户权利进行限制的条款、约定法律适用与管辖的条款等。限制、免责条款或者其他涉及您重大权益的条款可能以加粗等形式提示您重点注意。
      如您未满18周岁，或存在其他不具备与用户行为相适应的民事行为能力的情形，请您在法定监护人(“监护人”)的陪同、指导下阅读本协议，并在确保监护人同意本协议内容后使用平台。您及您的监护人应依照法律法规规定，承担同意本协议及使用平台而导致的相应后果。
      除非您已充分阅读、完全理解并接受本协议所有条的，均视为您已阅读并同意本协议。协议即在您与腾博会官网之间产生法律效力，成为对双方均具有约束力的法律文件。
      1.2协议范围
      本协议是您与腾博会官网之间关于您下载、安装、使用平台所订立的协议，包括本协议正文、《隐私政策》及腾博会官网已经发布的或将来可能发布/更新的并采取合理途径通知的各类规则、规范、通知等。所有规则均为本协议不可分割的组成部分，且具有同等法律效力。
      二、账号管理 2.1账号获得
      平台为您提供注册及登录通道，您可按照页面提示填写信息、阅读并同意本协议，在完成全部注册登录程序后，成为平台用户。您还需要根据法律法规的规定(如有)填写您真实的身份信息，否则您可能无法使用平台服务或在使用平台服务过程中受到限制。您了解并同意，您有义务保持您提供信息的真实性、有效性及完整性。
      您注册获得的账号、设置的密码是您登录并以用户身份使用平台的凭证，你应当谨慎使用、妥善保管。您须对账号进行的所有活动和行为负责。若因您保管不善导致的盗号、密码丢失、账号被非法使用等责任由您自行承担。
      您不得恶意注册账号，包括但不限于频繁注册、批量注册、使用他人身份注册或其他不以正常使用平台服务为目的的账号注册行为。
      2.2账号信息设置
      您在注册或使用平台时设置的名称、头像和简介等账号信息资料应遵守法律法规、社会道德风尚和信息真实性等原则，不得出现不良信息，不得冒用他人姓名、名称、字号、头像等或采取其他足以引起混淆的方式设置账号，不得侵害第三方的合法权益。
      2.3账号使用及权属
      部分第三方网站或者服务可以用账号作为其登录途径之一。您知晓，除非腾博会官网特别说明外，这些网站或者服务并非腾博会官网运营，您应自行判断此类第三方网站或者服务的安全性和可用性，并自行承担相关风险和责任。
      由于您的账号关联您的个人信息及平台商业信息，您的账号仅限您本人使用。未经腾博会官网同意，您直接或间接授权第三方使用您账号或获取您账号项下信息的行为无效。如腾博会官网判断您账号的使用可能违反法律法规、危及您的账号安全及/或平台信息安全的，腾博会官网可拒绝提供相应服务或终止本协议。腾博会官网有权视情况封禁或注销、回收该账号，由此带来的包括但不限于内容、虚拟财产清空等损失由您本人及该账号使用者自行承担。
      2.4账号注销及回收
      您可以依照平台的流程申请注销您的账号，但您仍应对您在注销账号前或使用平台服务期间的行为承担相应责任。注销成功后，除非法律法规另有规定，腾博会官网不会为您提供账号记录、内容、虚拟财产等恢复或提供服务，请您谨慎操作。
      您理解并同意，为了充分使用账号资源，如您在注册后未及时进行初次登录使用或连续超过六个月未登录账号并使用，且不存在未到期或未履行完毕的持续性平台服务的，腾博会官网有权收回您的账号。
      部分第三方网站或者服务可以用账号作为其登录途径之一。您知晓，除非腾博会官网特别说明外，这些网站或者服务并非腾博会官网运营，您应的账号。如您的账号被收回，您无法通过您此前持有的账号登录并使用平台，您账号下保存的个性化设置和使用记录也将无法恢复。
      2.5账号违规处置
      若您在注册或使用账号时，存在任何违反法律法规或不符合本协议约定的行为，腾博会官网有权不予注册;已经注册的，腾博会官网有权视情况要求用户限期改正、或单方采取短期封禁、永久封禁、注销账号等措施。
      三、平台服务 3.1内容浏览
      您可通过平台浏览订阅各类信息和内容。您对平台中的内容须自行加以判断，并承担因使用内容而引起的风险。除法律法规明确规定外，您因该等内容进行的购买或交易或因前述内容遭受的损害或损失由您自行承担。
      3.2内容发布
      您可使用平台发表属于您原创或您有权发表的观点看法、文字、信息、图片、音视频等内容。您在平台发布的任何内容、信息均不代表腾博会官网的观点、立场或政策，除法律法规明确规定外，您须独立承担所发布内容的责任。
      您通过平台发布内容时必须遵守有关法律法规，不得利用平台制作、复制、发布、传播、储存任何违反法律法规、危害国家安全、可能对用户财产安全或信息安全造成损失的信息、营销信息、低俗不当信息、不良信息以及不实信息等内容。
      您不得在平台上利用基于深度学习、虚拟现实等新技术制作、发布、传播虚假内容信息。
      3.3商品与服务交易
      您可使用平台进行商品与服务的交易，如您是未成年人或限制民事行为能力人，请在监护人同意后进行交易。
      进行交易时请您务必仔细确认所购商品的品名、价格、数量、型号、规格、尺寸或服务的时间、内容、限制性要求等重要事项，并在下单时核实您的联系地址、电话、收货人等信息。如您填写的收货人非您本人，则该收货人的行为和意思表示产生的法律后果均由您承担。
      您的交易行为应当基于真实的消费需求，不得存在对商品或服务实施恶意购买、恶意维权等扰乱平台正常交易秩序的行为。基于维护平台交易秩序及交易安全的需要，腾博会官网发现上述情形时可主动执行关闭相关交易订单等操作。
      如您的交易相对方存在利用平台系统漏洞、规则漏洞，不正当获取平台补贴等任何利益的行为，基于维护平台交易秩序、交易安全及腾博会官网合法权益的需要，腾博会官网发现上述情形时可主动执行关闭相关交易订单等操作。
      本协议终止后，对于您在本协议存续期间产生的交易订单，腾博会官网可通知交易相对方，并有权自主决定或者根据交易相对方的意愿决定是否关闭未完成的交易订单;如腾博会官网未关闭交易订单，则您应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的法律责任及损失。
      3.4内容分享与转发
      您可对平台的信息内容进行分享、转发，但未经腾博会官网书面许可，您不得复制、读取、采用、统计平台的信息内容及相关数据，或者进行任何形式的销售和商业使用，或者向第三方泄露、提供或允许第三方为任何方式的使用。
      3.5活动参与
      您可通过平台参与腾博会官网不定期组织的各类线上、线下互动活动，但您不得采取任何不正当或作弊的违规手段参与活动，否则腾博会官网有权限制或取消您参与活动的资格，并有权取消、追讨您已获得、领取的奖励，并追究您的法律责任。
      3.6直播
      如您年满18周岁，您可通过平台开展网络直播活动，并遵守《直播服务协议》《社区直播规范》的协议约定。
      3.7服务费用
      腾博会官网为向您提供的平台服务付出了大量的成本，除平台明示的收费业务外，腾博会官网向您提供的平台服务是免费的。如未来腾博会官网向您收取合理费用，腾博会官网会采取合理方式并以合理的期限提前通知您，确保您有充分选择的权利。
      在腾博会官网降低收费服务的收费标准或者将收费服务改为免费服务提供时，腾博会官网保留不对原付费用户提供退费或者费用调整之权利。
      四、权利与许可 4.1平台声明
      【平台知识产权】平台所提供的内容，包括但不限于视频、图文、文字表述及其组合、界面设计、版面框架、图标、商标等，除涉及第三方授权的软件、内容外，均归属腾博会官网所有，受中华人民共和国著作权法、商标法、专利法、反不正当竞争法及国际条约等法律法规的保护。未经书面允许，用户不得为任何目的擅自使用、复制、再造这些内容、或创造与内容有关的派生产品。
      【平台使用规范】除非法律法规允许或腾博会官网书面许可，您不得从事下列行为:
      对平台进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现平台的源代码;
      以任何方式(包括但不限于盗链、冗余盗取、非法抓取、模拟下载、深度链接、假冒注册等)直接或间接盗取平台的视频、图文、用户信息等信息内容;
      通过非腾博会官网开发、授权、许可的第三方软件、插件、外挂、系统，登录或使用平台，或对平台的正常运行进行干扰、破坏、修改或施加其他影响;
      利用或针对平台进行任何危害计算机网络安全的行为，包括但不限于:非法侵入网络、干扰网络正常功能、窃取网络数据等危害网络安全的活动，或为上述活动提供程序、工具等支持与帮助;企图干涉、破坏平台系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为等;
      其他以任何不合法的方式、为任何不合法的目的、或与腾博会官网为此制定的其他规范和标准不一致的方式使用平台。
      4.2用户内容及信息授权
      您在平台发布、上传的内容(包括但不限于文字、图片、视频、音频等)应有合法来源，相关内容为您所有或您已获得必要的授权
      除非有相反证明，您理解并同意，为使用户内容得到更好的分享及推广，提高其传播价值及影响力，您授予腾博会官网免费的、不可撤销的、非排他的、无地域限制的许可使用，包括:存储、使用、传播、复制、修订、改编、汇编、出版、展示、翻译、表演用户内容或制作派生作品，以已知或日后开发的形式、媒体或技术将内容纳入其它作品，再许可第三方按照前述方式使用的权利，以及以自身名义或委托专业第三方对侵犯您上传发布的享有知识产权的内容进行取证、发起投诉或提起诉讼的权利。
      为免歧义，上述许可包括使用、复制和展示用户内容中受保护的个人形象、肖像、姓名、商标、品牌、标识及其他营销推广素材、物料的权利和许可。
      腾博会官网不会将您的个人信息转移或披露给任何第三方，除非:
      相关法律法规或司法机关、行政机关要求。
      为完成合并、分立、收购或资产转让而转移。 为提供您要求的服务所必需。
      依据其他相关协议规则可以转移或披露给任何第三方的情形。 4.3侵权投诉
      腾博会官网尊重并保护您及他人的知识产权、名誉权、姓名权、隐私权等合法权益。您保证，在平台上传的文字、图片、视频、音频、链接等不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等合法权益。否则，腾博会官网有权在收到权利方或者相关方通知的情况下移除该涉嫌侵权内容。
      您认为平台上的内容侵犯您的合法权益，您可通过邮箱向我们提供相关文件或信息(包括内容链接、身份材料、享有权利、及存在侵权行为的证明文件等)。腾博会官网将在收到有效通知后判断决定是否对涉嫌侵权的内容进行处理。
      除非另有证明，腾博会官网储存在其服务器上的数据是用户使用平台的唯一有效证据。
      五、法律责任 5.1违约处理
      如您违反本协议约定，腾博会官网有权作出独立判断，立即暂停或终止向您提供部分或全部平台服务，包括禁言、屏蔽信息、删除发布内容、封禁账号、注销账号等措施。您应自行承担自身损失(包括但不限于虚拟财产清空、无法正常使用账号及相关平台服务、无法正常获取您账号内资产或其他权益等)，以及相应法律责任。
      5.2责任承担
      您承担法律责任的形式包括但不限于:对受到侵害者赔偿损失、赔礼道歉、消除影响、返还财产等。如您的行为给腾博会官网造成损失的，您应承担全部赔偿责任，包括行政处罚或损害赔偿款等直接损失以及商誉损失、和解款、律师费、诉讼费等间接损失。
      5.3反商业贿赂
      您充分了解，任何向腾博会官网的雇员或顾问等提供实物、现金、现金等价物、劳务、旅游等价值明显超出正常商务洽谈范畴的利益，均视为商业贿赂行为。发生前述情形的，腾博会官网可立即终止与您的所有合作并追究您的法律责任。
      5.4关联处理
      您因违约导致腾博会官网终止本协议时，出于维护平台秩序的目的，腾博会官网可根据与您在其他协议项下达成的约定终止与您在其他协议项下的合作。如您违反与您在其他合作项下订立的协议，腾博会官网亦有权终止本协议。除前述协议终止外，腾博会官网亦可关联追究您的违约责任。
      5.5信息公示
      腾博会官网可将对您上述违约行为的处置信息，以及其他经国家行政或司法机关生效法律文书确认的违法信息，在平台上予以公示。对涉嫌违反法律法规的行为，我们有义务保存有关记录，向国家有关机关报告并配合调查。
      六、责任限制与免责事由 6.1第三方服务
      您在使用平台某一特定服务时，该服务可能会另有单独的协议或规则，您在使用该项服务前请阅读并同意相关的单独协议或规则。若该服务由第三方提供，则腾博会官网和第三方对可能出现的纠纷在法律法规和约定的范围内各自承担责任。
      6.2责任限制
      本协议中包含的所有免责和责任限制条款仅适用于法律法规允许的最大限度内。
      6.3平台责任承担
      腾博会官网仅按照相关法律法规的规定承担平台责任，我们不对第三方的诽谤、犯罪或其他非法行为承担责任，也不赔偿因此造成的您的损失，但因我们对您遭受的损失负有法定过错的除外。
      6.4服务变更、中断、终止
      腾博会官网始终在不断变更和改进平台服务，我们会尽最大努力向您提供服务，确保服务的连贯性和安全性。基于此，我们可能会对平台进行更新，您应将软件更新到最新版本，否则我们不保证您可正常使用平台。
      您理解并同意，腾博会官网根据自身商业决策、政府行为、不可抗力等原因可能会选择中止、中断及终止平台的部分或全部服务。如有此等情形发生，我们会采取合理的方式并以合理的期限提前通知您，除法律法规另有规定外，我们不承担由此对您造成的损失。
      在腾博会官网发生合并、分立、收购、资产转让时，腾博会官网可在单方通知您后，将本协议下部分或全部平台服务及相应的权利义务转交由第三方运营或履行。具体受让主体以腾博会官网通知为准。
      如发生下列任何一种情形，腾博会官网有权不经通知而中断或终止向您提供服务:
      根据法律法规规定您应提交真实信息，而您提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明。
      您违反相关法律法规的规定或违反本协议的约定。
      按照法律法规规定，或司法机关或主管部门的要求。
      出于安全的原因或其他必要的情形。 七、协议变更与终止 7.1协议变更通知
      腾博会官网有权在必要时修改本协议内容，并将通过合理的方式(包括但不限于平台公告、系统消息、站内信、手机短信、电子邮件等)及合理的期限提前通知您，该等通知于发送之日视为已送达您。您可以通过平台相关页面查阅最新版本的协议内容。
      7.2变更协议生效
      本协议变更生效后，如您继续使用平台，即视为您已接受修改后的协议。如您不接受修改后的协议，应当停止使用平台。
      7.3协议终止
      您注销您的账号或腾博会官网通知您终止为您提供平台服务(包括但不限于封禁账号、注销账号等)时，本协议将同时终止，腾博会官网将不再另行通知您终止本协议。
      八、法律适用与管辖 8.1本协议签订地为中华人民共和国广州市天河区。
      8.2本协议的订立、生效、履行、解释、修订、终止及纠纷解决，适用中华人民共和国法律法规(仅为本协议之目的，不包括香港特别行政区及澳门特别行政区法律法规，和中国台湾地区有关规定)。
      8.3若您与腾博会官网之间发生任何纠纷或争议，由腾博会官网与您协商解决。协商不成的，您同意将纠纷或争议提交被告所在地人民法院管辖。
      九、其他
      9.1本协议所有条款的标题仅为阅读和理解方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
      9.2本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
      9.3如您对本协议有任何意见或建议，您可向平台反馈，我们会给予您必要的帮助。
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue'
export default {
    setup(){
        onMounted(() => {
            document.title = "用户协议"
        })
    }
}
</script>

<style scoped>
.Info {
  text-align: center;
  background: #fff;
  padding:0px 10px 20px 10px;
  width: 1200px;
  margin: 0 auto;
}
</style>
<template>
    <div class="Info">
        <h1>关于腾博会官网科技网</h1>
        <div style="margin-top: 20px;">
            腾博会官网科技网是由腾博会官网运营的一站式软件外包、项目外包服务的平台，平台精选了多个优质接包方入驻，涵盖电商、物联网、软件开发、行业应用开发、管理系统等多个领域。腾博会官网科技网为需求方和专业服务提供者搭建桥梁，旨在简化项目开发和交付流程。
            <img src="../assets/banner_Icon.png" style="width: 100%;"/> 
        </div>
    </div>
</template>
<script>
import { onMounted } from 'vue'
export default {
    setup(){
        onMounted(() => {
            document.title = "腾博会官网"
        })
    }
}
</script>

<style scoped>
.Info {
  text-align: center;
  background: #fff;
  padding:0px 10px 20px 10px;
  width: 1200px;
  margin: 0 auto;
}
</style>
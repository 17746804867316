<template>
  <div class="Info">
    <h1>隐私政策</h1>
    <div>
      1.腾博会官网及其关联方作为的运营者，深知个人信息对您的重要性，将按照法律法规的规定，保护您的个人信息及隐私安全。制定本“隐私政策”并特别提示：希望您在使用及相关服务前仔细阅读并理解本隐私政策，以便做出适当的选择。
      1.1.在您使用服务过程中，为识别账号异常状态、了解产品适配性、向您提供更契合您需求的页面展示和搜索结果，可能会自动收集您的使用情况并存储为网络日志信息，包括：
      1.2设备信息：会根据您在软件安装及/或使用中的具体操作，接收并记录您所使用的设备相关信息（包括设备型号、操作系统版本、设备设置、MAC地址及IMEI、IDFA、OAID设备标识符、设备环境、移动应用列表软硬件特征信息）、设备所在位置相关信息（包括您授权的GPS位置以及WLAN接入点、蓝牙和基站等传感器信息）。您也可以通过搜索来精准地找到您所需要的商品或服务。会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的商品。请您注意，您的搜索关键词信息无法单独识别您的身份，不属于您的个人信息，有权以任何的目的对其进行使用；只有当您的搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，则在结合使用期间，会将您的搜索关键词信息作为您的个人信息，与您的搜索历史记录一同按照本隐私政策对其进行处理与保护。
      1.3服务日志信息：当您使用的客户端提供的产品或服务时，会自动收集您对服务的详细使用情况，作为服务日志保存，包括浏览、点击查看、搜索查询、收藏、添加至购物车、交易、售后、关注分享信息、发布信息，以及IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间。
      1.4请注意，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息。如果将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，会将这类信息做匿名化、去标识化处理。
      2.本隐私政策将帮助您了解：
      2.1.•会遵循隐私政策收集、使用您的信息，但不会仅因您同意本隐私政策而采用强制捆绑的方式一揽子收集个人信息。
      2.2.当您使用或开启相关功能或使用服务时，为实现功能、服务所必需，会收集、使用相关信息。除非是为实现基本业务功能或根据法律法规要求所必需的必要信息，您均可以拒绝提供且不影响其他功能或服务。将在隐私政策中逐项说明哪些是必要信息。
      2.3.如果您未登录账号，会通过设备对应的标识符信息来保障信息推送的基本功能。如果您登录了账号，会根据账号信息实现信息推送。
      2.4.为了帮您发现更多好友，可能会申请通讯录权限。
      2.5.尊重您的选择权，如果您不希望被推荐给好友，您可以在“我—设置—隐私设置”中随时关闭。
      2.6.通讯录、精确地理位置、摄像头、麦克风、相册权限，均不会默认开启，只有经过您的明示授权才会在为实现特定功能或服务时使用，您也可以撤回授权。特别需要指出的是，即使经过您的授权，获得了这些敏感权限，也不会在相关功能或服务不需要时而收集您的信息。
      2.7.•本隐私政策适用于您通过应用程序、官方网站、供第三方网站和应用程序使用的软件开发工具包（SDK）和应用程序编程接口（API）方式来访问和使用的产品和服务。
      3.下文将帮您详细了解如何收集、使用、存储、传输、共享、转让（如适用）与保护个人信息；帮您了解查询、访问、删除、更正、撤回授权个人信息的方式。
      3.1如何收集和使用个人信息
      3.1.1.会按照如下方式收集您在使用服务时主动提供的，以及通过自动化手段收集您在使用功能或接受服务过程中产生的信息：
      3.2.1注册、登录、认证 3.2.1.1注册、登录账号
      a.当您注册、登录及相关服务时，您可以通过手机号创建账号，并且您可以完善相关的网络身份识别信息（头像、昵称、密码），收集这些信息是为了帮助您完成注册。您还可以根据自身需求选择填写性别、生日、地区及个人介绍来完善您的信息。
      b.您也可以使用第三方账号登录并使用，您将授权获取您在第三方平台注册的公开信息（头像、昵称以及您授权的其他信息），用于与账号绑定，使您可以直接登录并使用本产品和相关服务。
      c.登录第三方账号：当您使用账号登录第三方账号时，经过您的同意，可能会共享昵称、头像以及您授权的其他信息。
      d.基于与通信运营商的合作，当您使用“一键登录”功能时，经过您的明示同意，运营商会将您的手机号码发送给，便于为您提供快捷的登录服务。手机号码属于个人敏感信息，如果拒绝提供将无法使用“一键登录”方式注册登录，但不影响您通过其他方式注册登录，也不影响其他功能的正常使用。
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue'
export default {
    setup(){
        onMounted(() => {
            document.title = "隐私政策"
        })
    }
}
</script>

<style scoped>
.Info {
  text-align: center;
  background: #fff;
  padding: 20px 10px;
  width: 1200px;
  margin: 0 auto;
}
</style>
// router.js
import { createRouter, createWebHashHistory } from 'vue-router';
 
// 引入Vue组件
import HomePage from '../view/HomePage.vue';
import GoodsPage from '../view/GoodsPage.vue';
import JoinInPage from '../view/JoinInPage.vue';
import InformationPage from '../view/InformaTion.vue';
import articleDetails from '../view/articleDetails.vue';
import goodsDetails from '../view/goodsDetails.vue';
import UserPage from '../view/UserPage.vue';
import AboutUs from '../view/AboutUs.vue';
import Agreement from '../view/agreementMain.vue';
import privacyMain from '../view/privacyMain.vue';
// 定义路由
const routes = [
  { path: '/', component: HomePage },
  { path: '/Goods', component: GoodsPage },
  { path: '/JoinIn', component: JoinInPage },
  { path: '/Information', component: InformationPage },
  { path: '/articleDetails/:id', component: articleDetails },
  { path: '/goodsDetails/:id/:name', component: goodsDetails, name: 'goodsDetails', },
  { path: '/UserPage/:id/:name', component: UserPage ,name: 'UserPage'},
  { path: '/AboutUs', component: AboutUs },
  { path: '/Agreement', component: Agreement },
  { path: '/privacyMain', component: privacyMain },
];
 
// 创建router实例
const router = createRouter({
  history: createWebHashHistory('/'),
  routes,
});
 
export default router;
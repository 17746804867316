<template>
  <div class="Page">
    <TopPage></TopPage>
    <router-view></router-view>
    <uFooterPage></uFooterPage>
  </div>
</template>

<script>
import TopPage from "./components/TopPage.vue";
import uFooterPage from "./components/UFooterPage.vue";
import { useRoute } from 'vue-router'
export default {
  name: "App",
  components: {
    TopPage,
    uFooterPage,
  },
  setup(){
    const route = useRoute()
    return{
      route
    }
  }
};
</script>

<style>
* {
  padding: 0px;
  margin: 0px;
}
.Page{
  background: #f9f9f9;
}
</style>

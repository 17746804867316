<template>
    <div>
        <Banner></Banner>
        <div class="Main">
            <div class="Main_title">行业资讯</div>
            <div class="Main_top" @click="GoarticleDetails(0)">
                <img class="Main_top_image" src="../assets/icon_2.png" />
                <div class="Main_top_right">
                    <div class="Main_top_right_title">定制网站开发有哪些特点？广州网站开发流程  </div>
                    <div class="Main_top_right_desc">互联网发展日新月异，网站开发是企业网站建设过程的一个重点环节，如何从众多企业官网中脱颖而出，就需要企业定制开发一个网站，结合企业特点，在网站风格，网站内容，网站结构和功能上做网站开发定制，那么，企业网站开发定制的具体内容是什么呢？下面来为大家介绍。</div>
                    <!-- <div class="Main_top_right_time">2023-09-16</div> -->
                </div>
            </div>
            <div class="Main_list">
                <div class="Main_list_item" @click="GoarticleDetails(1)">
                    <div class="Main_list_item_name"> APP的开发需要多少钱</div>
                    <!-- <div class="Main_list_item_time">2023-08-21</div> -->
                </div>
                <div class="Main_list_item" @click="GoarticleDetails(2)">
                    <div class="Main_list_item_name">小程序定制开发的优势有哪些？</div>
                    <!-- <div class="Main_list_item_time">2023-08-21</div> -->
                </div>
                <div class="Main_list_item" @click="GoarticleDetails(3)">
                    <div class="Main_list_item_name">广州软件开发互联网医院系统 </div>
                    <!-- <div class="Main_list_item_time">2023-09-19</div> -->
                </div>
                <div class="Main_list_item" @click="GoarticleDetails(4)">
                    <div class="Main_list_item_name">OA系统与工单系统的差别</div>
                    <!-- <div class="Main_list_item_time">2023-09-14</div> -->
                </div>
                <div class="Main_list_item" @click="GoarticleDetails(5)">
                    <div class="Main_list_item_name">如何找到优秀的网站建设公司？</div>
                    <!-- <div class="Main_list_item_time">2023-09-21</div> -->
                </div>
                <div class="Main_list_item" @click="GoarticleDetails(6)">
                    <div class="Main_list_item_name">如何选择适合自己企业的OA系统</div>
                    <!-- <div class="Main_list_item_time">2023-09-25</div> -->
                </div>
                <div class="Main_list_item" @click="GoarticleDetails(7)">
                    <div class="Main_list_item_name">Erp系统开发对企业来说能产生哪些收益</div>
                    <!-- <div class="Main_list_item_time">2023-09-26</div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted } from 'vue'
import Banner from '@/components/Banner.vue';
import { useRouter } from 'vue-router'
export default {
    components:{
        Banner
    },
    setup(){
        onMounted(() => {
            document.title = "行业资讯"
        });

        const router = useRouter();
        function GoarticleDetails(e){
            router.push(`/articleDetails/${e}`);
        }
        function GogoodsDetails(e){
            router.push(`/goodsDetails/${e}`);
        }
        return{
            GoarticleDetails,
            GogoodsDetails,
        }
    }
}
</script>

<style scoped>
.Main{
    width: 1200px;
    margin: 0 auto;
    
}
.Main_title{
    font-size: 30px;
    text-align: center;
    padding: 40px 0px;
    font-weight: 600;
}
.Main_top{
    display: flex;
    align-content: center;
}
.Main_top_image{
    width: 300px;
    height: 150px;
}
.Main_top_right{
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 30px;
    cursor: pointer;
}
.Main_top_right_title{
    font-size: 20px;
}
.Main_top_right_desc{
    font-size: 18px;
    margin-top: 10px;
    overflow:hidden; 
    text-overflow:ellipsis;
    display:-webkit-box; 
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2; 
}
.Main_top_right_time{
    color: rgb(153, 153, 153);
    font-size: 16px;
}
.Main_list{
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0px;
}
.Main_list_item{
    width: calc(50% - 40px);
    display: flex;
    justify-content: space-between;
    padding: 10px 0px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
}
.Main_list_item:nth-child(even){
    margin-left: 40px;
}

.Main_list_item_name{
    font-size: 13px;
    color: #000;
}
.Main_list_item_time{
    color: rgb(153, 153, 153);
    font-size: 11px;
}

.goodsList{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
}
.goodsList_item{
    width:280px;
    margin-right: 20px;
    margin-top: 20px;
    cursor: pointer;
}
.goodsList_item:nth-child(4n){
    margin-right: 0px;
}
.goodsList_item_image{
    width: 280px;
    height: 280px;
    display: block;
    border-radius: 5px 5px 0 0;
}
.goodsList_item_text{
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, .2);
    padding: 10px;
}
.title{
    font-size: 15px;
    overflow:hidden; 
    text-overflow:ellipsis;
    display:-webkit-box; 
    -webkit-box-orient:vertical;
    -webkit-line-clamp:2;
}
.Time{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 12px;
    color: rgb(153, 153, 153);
}
</style>
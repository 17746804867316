<template>
  <div class="Info">
    <el-form>
      <el-form-item label="标题">
        <el-input style="width: 400px" />
      </el-form-item>
      <el-form-item label="简介">
        <el-input />
      </el-form-item>
      <el-form-item label="位置">
        <el-input style="width: 400px" />
      </el-form-item>
      <el-form-item label="图片">
        <el-upload>
          <el-icon class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item label="详情">
        <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editorRef"
          :defaultConfig="toolbarConfig"
          :mode="mode"
        />
        <Editor
          style="height: 500px; width: 100%; overflow-y: hidden"
          v-model="valueHtml"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="handleCreated"
        />
      </el-form-item>
      <div class="Submit_Info">
        <el-button type="primary" @click="onSubmit">确定</el-button>
        <el-button>取消</el-button>
      </div>
    </el-form>
    <LoginPage ref="loginpage"></LoginPage>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import { onBeforeUnmount, ref, shallowRef } from "vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { Plus } from "@element-plus/icons-vue";
import LoginPage from "@/components/LoginPage.vue";
export default {
  components: { Editor, Toolbar, Plus,LoginPage },
  setup() {

    onMounted(() => {
        document.title = "发布服务"
    });


    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef();
    let loginpage = ref()
    // 内容 HTML
    const valueHtml = ref("");
    const toolbarConfig = {};
    const editorConfig = { placeholder: "请输入内容..." };

    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value;
      if (editor == null) return;
      editor.destroy();
    });

    const handleCreated = (editor) => {
      editorRef.value = editor; // 记录 editor 实例，重要！
    };

    function onSubmit(){
      loginpage.value.InitdialogVisible()
    }

    return {
      editorRef,
      valueHtml,
      mode: "default", // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated,
      onSubmit,
      loginpage
    };
  },
};
</script> 

<style scoped>
.Info {
  width: 1200px;
  margin: 30px   auto;
  background: #fff;
  padding:20px 20px 20px 20px; 
}
.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  text-align: center;
  border: 1px solid #ccc;
}
.Submit_Info{
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
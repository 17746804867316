<template>
    <div class="Info">
        <div class="TopBox" >
            <img class="TopBox_image" src="../assets/91/cover.png" v-if="Type == 1"/>
            <img class="TopBox_image" src="../assets/U+/cover.png" v-if="Type == 2"/>
            <img class="TopBox_image" src="../assets/WE/cover.png" v-if="Type == 3"/>
            <img class="TopBox_image" src="../assets/Charging/cover.png" v-if="Type == 4"/>
            <img class="TopBox_image" src="../assets/ClothingERP/cover.png" v-if="Type == 5"/>
            <img class="TopBox_image" src="../assets/Official/cover.png" v-if="Type == 6"/>
            <img class="TopBox_image" src="../assets/Supplier/cover.png" v-if="Type == 7"/>
            <img class="TopBox_image" src="../assets/freshproduce/cover.png" v-if="Type == 8"/>
            <img class="TopBox_image" src="../assets/Craftsmanship/cover.png" v-if="Type == 9"/>
            <img class="TopBox_image" src="../assets/JialiRen/cover.png" v-if="Type == 10"/>
            <img class="TopBox_image" src="../assets/Homelife/cover.png" v-if="Type == 11"/>
            <img class="TopBox_image" src="../assets/Whale/cover.png" v-if="Type == 12"/>
            <img class="TopBox_image" src="../assets/Neighbor/cover.png" v-if="Type == 13"/>
            <img class="TopBox_image" src="../assets/Maternal/cover.png" v-if="Type == 14"/>
            <img class="TopBox_image" src="../assets/Timber/cover.png" v-if="Type == 15"/>
            <img class="TopBox_image" src="../assets/Platform/cover.png" v-if="Type == 16"/>
            <img class="TopBox_image" src="../assets/Community/cover.png" v-if="Type == 17"/>
            <img class="TopBox_image" src="../assets/Microfinance/cover.png" v-if="Type == 18"/>
            <img class="TopBox_image" src="../assets/ornaments/cover.png" v-if="Type == 19"/>
            <img class="TopBox_image" src="../assets/Encounter/cover.png" v-if="Type == 20"/>
            <img class="TopBox_image" src="../assets/Idlefriends/Idlefriends1.jpg" v-if="Type == 21"/>
            <img class="TopBox_image" src="../assets/Yafu/cover.png" v-if="Type == 22"/>
            <img class="TopBox_image" src="../assets/Medical/cover.png" v-if="Type == 23"/>
            <img class="TopBox_image" src="../assets/Zhongtian/cover.png" v-if="Type == 25"/>
            <img class="TopBox_image" src="../assets/Music/cover.png" v-if="Type == 26"/>
            <img class="TopBox_image" src="../assets/meeting/cover.png" v-if="Type == 27"/>
            <img class="TopBox_image" src="../assets/Enterprise/cover.png" v-if="Type == 28"/>
            <img class="TopBox_image" src="../assets/liuxuesheng/cover.png" v-if="Type == 29"/>
            <img class="TopBox_image" src="../assets/crown/cover.png" v-if="Type == 30"/>
            <img class="TopBox_image" src="../assets/curriculum/cover.png" v-if="Type == 31"/>
            <img class="TopBox_image" src="../assets/train/cover.png" v-if="Type == 32"/>
            <div class="TopBox_right">
                <div class="TopBox_right_title" v-if="Type == 1">贷款项目</div>
                <div class="TopBox_right_title" v-if="Type == 2">家具项目</div>
                <div class="TopBox_right_title" v-if="Type == 3">销售平台</div>
                <div class="TopBox_right_title" v-if="Type == 4">充电桩查询</div>
                <div class="TopBox_right_title" v-if="Type == 5">服装ERP管理系统</div>
                <div class="TopBox_right_title" v-if="Type == 6">餐饮行业官网</div>
                <div class="TopBox_right_title" v-if="Type == 7">采购平台</div>
                <div class="TopBox_right_title" v-if="Type == 8">生鲜平台</div>
                <div class="TopBox_right_title" v-if="Type == 9">全屋定制家具</div>
                <div class="TopBox_right_title" v-if="Type == 10">美容行业平台</div>
                <div class="TopBox_right_title" v-if="Type == 11">家居生活</div>
                <div class="TopBox_right_title" v-if="Type == 12">选房平台</div>
                <div class="TopBox_right_title" v-if="Type == 13">电商带货</div>
                <div class="TopBox_right_title" v-if="Type == 14">母婴平台</div>
                <div class="TopBox_right_title" v-if="Type == 15">木材货源</div>
                <div class="TopBox_right_title" v-if="Type == 16">社交平台</div>
                <div class="TopBox_right_title" v-if="Type == 17">微宠平台</div>
                <div class="TopBox_right_title" v-if="Type == 18">理财项目</div>
                <div class="TopBox_right_title" v-if="Type == 19">手游项目</div>
                <div class="TopBox_right_title" v-if="Type == 20">二手交易</div>
                <div class="TopBox_right_title" v-if="Type == 21">校友服务</div>
                <div class="TopBox_right_title" v-if="Type == 22">购物app</div>
                <div class="TopBox_right_title" v-if="Type == 23">医疗器械项目</div>
                <div class="TopBox_right_title" v-if="Type == 24">营销管理系统</div>
                <div class="TopBox_right_title" v-if="Type == 25">手机数码</div>
                <div class="TopBox_right_title" v-if="Type == 26">音乐平台</div>
                <div class="TopBox_right_title" v-if="Type == 27">会议系统</div>
                <div class="TopBox_right_title" v-if="Type == 28">人脉项目</div>
                <div class="TopBox_right_title" v-if="Type == 29">学生管理</div>
                <div class="TopBox_right_title" v-if="Type == 30">设计项目</div>
                <div class="TopBox_right_title" v-if="Type == 31">课程管理平台</div>
                <div class="TopBox_right_title" v-if="Type == 32">培训管理服务支撑系统</div>
                <div class="TopBox_right_laber">
                    <div class="TopBox_right_laber_name">特色标签:</div>
                    <div class="TopBox_right_laber_main">开发服务</div>
                </div>
                <div class="TopBox_right_address">位置:广州市海珠区琶洲</div>
            </div>
        </div>

        <div class="Main">
            <div class="Main_left" v-if="Type == 1">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">贷款项目是一款小额、低息、无抵押的信用贷款产品。通过大数据智能匹配用户贷款需求，提供小额贷款、急速贷款、企业贷款、车贷、房贷、创业贷、信用卡申请等多种产品。满足用户的多种贷款需求。0-30万额度，快至1小时放款，随时解决用户燃眉之急。</div>
                <img class="Main_left_image" src="../assets/91/91.png" />
            </div>
            <div class="Main_left" v-if="Type == 2">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">家具项目是一款进行线上家具销售APP，主要为了方便广大上班族在空闲时间也能在手机上选购家具，不管是你喜欢简约风还是欧式风或中国风等，都能满足你的需求
                    产品方向通过平台进行家具买卖，新款推荐提供用户购买品牌家具设计原则整体视觉最求简洁大方，给用户最棒的体验，去除几杂将页面简化，进行大留白，使其界面简单明了，提高信息的便是独，内容扁平化提高用户使用率</div>
                <img class="Main_left_image" src="../assets/U+/U5.jpg" />
                <img class="Main_left_image" src="../assets/U+/U6.jpg" />
                <img class="Main_left_image" src="../assets/U+/U7.jpg" />
                <img class="Main_left_image" src="../assets/U+/U8.jpg" />
                <img class="Main_left_image" src="../assets/U+/U9.jpg" />
                <img class="Main_left_image" src="../assets/U+/U10.jpg" />
                <img class="Main_left_image" src="../assets/U+/U11.jpg" />
                <img class="Main_left_image" src="../assets/U+/U12.jpg" />
                <img class="Main_left_image" src="../assets/U+/U13.jpg" />
            </div>
            <div class="Main_left" v-if="Type == 3">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    项目背景
                    随着网络、通信和信息技术的突破性发展，电子商务营运而生。电子商务是基于互联网、以交易双方为主体，以电子支付和结算为手段、客户数据为依托的商务模式，使商家和供应商更紧密的联系起来，更快满足客户需求、在市场上的销售产品。
                    网上购物方便、快捷、在线支付，送货上门，具有优越性。网上购物已被人们接受，改变了传统的购物理念，加快了企业对市场的响应速度，提高了企业的服务质量和竞争力。
                    产品定位
                    类型:网上购物服务平台，打造从选择用户需要的产品到最终付款购买产品一系列商业流程，保证商家与用户之间的安全，便携式交易。
                </div>
                <img class="Main_left_image" src="../assets/WE/WE.jpg" />
            </div>
            <div class="Main_left" v-if="Type == 4">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    近年来，由于受到政策支持等因素的影响，我国新能源汽车行业得到迅速发展。但绝大多数地区的充电桩还无法普及。这对于二三线及以下城市的新能源车主而言极大的不便。
                    充电桩查询很好的解决了这样的问题，主要功能为搜寻周围充电桩，查看充电桩基本信息，同时对于远距离出行者，会根据沿途充电桩作出路径推荐。同时“充电桩”也是一个新能源车主的生活社区。
                </div>
                <img class="Main_left_image" src="../assets/Charging/Charging1.jpg" />
                <img class="Main_left_image" src="../assets/Charging/Charging2.gif" />
            </div>
            <div class="Main_left" v-if="Type == 5">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    这套ERP管理系统是公司应对自身使用需求而设计的，独立创新设计商户云平台操作系统需求:集业务员选择单位、签单、填写合同、申请开单和各级领导审批，查看于一体的ERP系统。要求简单明了、使用简单好操作，界面简约美观。
                </div>
                <img class="Main_left_image" src="../assets/ClothingERP/ClothingERP.jpg" />
            </div>
            <div class="Main_left" v-if="Type == 6">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    餐饮官网系统，是通往美食世界的桥梁。 菜品展示区，经典肉蟹煲、明虾煲、鸡翅煲等佳肴如艺术品般呈现，食材与工艺一目了然。在线订餐便捷高效，可按需选菜、定数量和送餐时间，多种支付方式确保流畅。优惠活动不断，推送最新福利与套餐，实惠满满。视觉设计精美，高清美食图片与舒适餐厅环境展示，带来沉浸式体验。餐饮官网系统以卓越功能和贴心服务，为您开启难忘的美食之旅，快来探索吧！
                </div>
                <img class="Main_left_image" src="../assets/Official/Official2.png" />
            </div>
            <div class="Main_left" v-if="Type == 7">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    以实体零件工厂为依托，以互联网信息化技术为手段，构建线上线下一体化的新型工厂零件采购模式，提供线上采购、型号查询、在线客服、在线支付、型号对比等多种服务。为客户提供便捷的互联网采购商城，对公司打造品牌形象，传播零件口碑，为打造工业互联网起着重要的推动作用
                </div>
                <img class="Main_left_image" src="../assets/Supplier/Supplier1.jpg" />
                <img class="Main_left_image" src="../assets/Supplier/Supplier2.jpg" />
                <img class="Main_left_image" src="../assets/Supplier/Supplier3.jpg" />
                <img class="Main_left_image" src="../assets/Supplier/Supplier4.jpg" />
                <img class="Main_left_image" src="../assets/Supplier/Supplier5.jpg" />
                <img class="Main_left_image" src="../assets/Supplier/Supplier6.jpg" />
            </div>
            <div class="Main_left" v-if="Type == 8">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    一个囊括生鲜市场的零售品台，通过线上APP和线下实体店结合为用户提供:生鲜零售，生鲜外卖..实用便利的功能。
                </div>
                <img class="Main_left_image" src="../assets/freshproduce/freshproduce.png" />
            </div>
            <div class="Main_left" v-if="Type == 9">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    产品概述
                    全屋定制家具是一款服务于家具终端的家具新零售平台，为消费者提供专业的家具搭配师一对一个性化服务，提供家具团购，厂价直销，实现真正的家具新零售，同时赋能商家，终端客户扫码，实现系统智能派单，为商家引流
                    页面展示
                    通过对产品和用户的分析，使界面传达视觉感受符合设计方向。其中服务关键词，更多在用户体验上提现、包括文案的描述、清晰的流程等进行很贴心的营造
                </div>
                <img class="Main_left_image" src="../assets/Craftsmanship/Craftsmanship1.jpg" />
            </div>
            <div class="Main_left" v-if="Type == 10">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    美容行业平台是一款专为追求美丽的你精心打造的专业美容应用。 打开首页，各类热门美容项目与顶尖技师精彩呈现，引领你踏入美丽新世界。“我的预约”功能贴心便捷，让你轻松管理美容行程，确保每一次美丽邂逅都准时开启。钱包模块安全高效，为你的美丽消费提供无忧的支付与资金管理保障。个人中心犹如你的专属美丽领地，可随心定制美容偏好，记录每一步美丽蜕变历程。丰富多元的项目涵盖广泛美容需求，满足不同肤质与独特审美追求。技师板块汇聚专业精英，详细展示他们的擅长领域与丰富经验，为你精心挑选美丽助力。照片板块生动展示真实惊艳的美容效果案例，给予你直观的决策参考。评论区则是用户交流分享的温馨空间，让你倾听他人的体验感悟与宝贵建议。 快来体验美容行业平台，开启属于你的璀璨美丽新传奇。
                </div>
                <img class="Main_left_image" src="../assets/JialiRen/JialiRen.png" />
            </div>
            <div class="Main_left" v-if="Type == 11">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    产品定位
                    家居生活通过大众体验分享，解决年轻上班族用户的痛点(不会搭配、不会挑单品、资金不足)，传达时尚舒适的感知体验。且更为人性化的为顾客提供他们能负担，设计精良，功能齐全，价格低廉的家居用品。
                    用户画像
                    用户画像的目的模拟出符合产品用户人群的用户形象，确定用户人群的目标和挖掘用户痛点。帮助设计师在后续的设计时，更多地站在用户的角度上考虑以免设计师脱离用户做自嗨的设计。用户画像的作用不止是在前期整理出来就完事了，它更像是个标签在设计过程中提醒着设计师要时刻从用户的角度考虑。
                </div>
                <img class="Main_left_image" src="../assets/Homelife/Homelife1.png" />
            </div>
            <div class="Main_left" v-if="Type == 12">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    选房平台是全国性的AI选房平台，实时汇总全国拍卖的房源，由具备多年法拍经验的法拍团队，为客户提供全程智能化的司法拍卖一站式服务。
                </div>
                <img class="Main_left_image" src="../assets/Whale/Whale2.gif" />
                <img class="Main_left_image" src="../assets/Whale/Whale3.gif" />
                <img class="Main_left_image" src="../assets/Whale/Whale4.gif" />
            </div>
            <div class="Main_left" v-if="Type == 13">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    产品介绍
                    邻仓是以“零售电商”和“外卖跑腿”为切入口，通过线上APP和线下实体店为用户提供:水果、蔬菜、零食、鲜肉等产品的服务性APP。
                    目标用户
                    针对城市居民打造出的一款便民惠民的一款手机生鲜购物超市。用户线上下单后，最晚会在30分钟内将用户所购商品送达，快捷、省时。
                </div>
                <img class="Main_left_image" src="../assets/Neighbor/Neighbor.png" />
            </div>
            <div class="Main_left" v-if="Type == 14">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    产品分析
                    用户画像 
                    母婴小程序的用户基本上都是 20-40 岁的女性，在职或全职母亲，家庭收入高低不等，没时间逛街，网上购物怕有假货，希望有个实体店做基础的购物平台。
                    产品目标 
                    实现线下门店与线下网店相结合，线下门店商品在线上售卖，线上客户向线下引流，实行线下线上会员统一管理，增加客户对门店的粘性,实时活动怎加客户忠诚度。
                    需求分析
                    随着电商平台的兴起，电商行业的网上店铺对实体店的打击那可是巨大的，那么实体店如何在逆境中寻求发展?在这方面有两个方案:
                    一是避免电子商务，不要积极面对电子商务，走差异化竞争的道路;
                    第二是扩展想法，与电子商务合作并与之竞争，并在经营实体店时进行实施,进行互联网推广,同时上线和下线携手并进。
                </div>
                <img class="Main_left_image" src="../assets/Maternal/Maternal1.jpg" />
                <img class="Main_left_image" src="../assets/Maternal/Maternal2.jpg" />
                <img class="Main_left_image" src="../assets/Maternal/Maternal3.jpg" />
                <img class="Main_left_image" src="../assets/Maternal/Maternal4.jpg" />
            </div>
            <div class="Main_left" v-if="Type == 15">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    用户需求:
                    用户通过app发布求购或者发布货源，
                    用户用过APP进行线上交易，购买所需货源，
                    用户场景:
                    1、木材商想要购买木材，用木材码头去搜索想要的货源，或者发布求购，来购买货品
                    2、当木材商手里拥有一批货需要寻找买家，用木材货源一键发布货源，卖货无忧。

                    产品定义
                    木材货源致力于成为木材行业最值得信赖的服务提供商;打造木材全产业链综合服务平台;成为整合交易、物流、仓储、金融等服务的互联网服务平台覆盖木材交易各个环节，线上平台交易、线下服务站联动;满足木业用户对“信息”的强烈需求。
                </div>
                <img class="Main_left_image" src="../assets/Timber/Timber3.jpg" />
                <img class="Main_left_image" src="../assets/Timber/Timber4.jpg" />
                <img class="Main_left_image" src="../assets/Timber/Timber5.jpg" />
                <img class="Main_left_image" src="../assets/Timber/Timber6.jpg" />
                <img class="Main_left_image" src="../assets/Timber/Timber7.jpg" />
                <img class="Main_left_image" src="../assets/Timber/Timber8.jpg" />
                <img class="Main_left_image" src="../assets/Timber/Timber9.jpg" />
                <img class="Main_left_image" src="../assets/Timber/Timber10.jpg" />
                <img class="Main_left_image" src="../assets/Timber/Timber11.jpg" />
                <img class="Main_left_image" src="../assets/Timber/Timber12.jpg" />
                <img class="Main_left_image" src="../assets/Timber/Timber13.jpg" />
                <img class="Main_left_image" src="../assets/Timber/Timber14.jpg" />
                <img class="Main_left_image" src="../assets/Timber/Timber15.jpg" />
            </div>
            <div class="Main_left" v-if="Type == 16">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    产品简介是专注服务艺术人群的综合平台，致力从“生活、学习、工作”满足艺术人群的大部分需求。您可以在APP上寻找艺术好友、社交互动;艺术教学;寻找兼职/全职工作;了解和报名艺术类活动/赛事/咨询等等。是才艺圈app是一款专业的艺术交流社区，涵盖了生活、学习、工作等多个方面的模块，用户可通过我是才艺圈app充分发展的兴趣，拓展你的圈子，在交流中共同成长。
                </div>
                <img class="Main_left_image" src="../assets/Platform/Platform2.jpg" />
                <img class="Main_left_image" src="../assets/Platform/Platform4.jpg" />
            </div>
            <div class="Main_left" v-if="Type == 17">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    产品介绍
                    微宠平台是一款以宠物为主题的社交交友软件，喜欢宠物小动物的你加入大家庭吧，这里都是爱宠人士，你可以和他们交流宠物喂养的经验、学习和分享，同时交到志同道合的好朋友
                </div>
                <img class="Main_left_image" src="../assets/Community/Community.jpg" />
            </div>
            <div class="Main_left" v-if="Type == 18">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    无需下载，微信即可访问。提供理财产品推荐、小额贷款、信用卡申请管理等服务。还有金融资讯与市场动态推送。采用严格安全措施与合规运营，保障用户信息和资金安全。界面简洁直观，操作便捷，可个性化设置。让您随时随地享受高效、安全的微金融服务，使金融变得简单轻松。快来体验，开启便捷金融之旅。
                </div>
                <img class="Main_left_image" src="../assets/Microfinance/Microfinance2.png" />
                <img class="Main_left_image" src="../assets/Microfinance/Microfinance3.png" />
                <img class="Main_left_image" src="../assets/Microfinance/Microfinance4.png" />
                <img class="Main_left_image" src="../assets/Microfinance/Microfinance5.png" />
                <img class="Main_left_image" src="../assets/Microfinance/Microfinance6.png" />
                <img class="Main_left_image" src="../assets/Microfinance/Microfinance7.png" />
                <img class="Main_left_image" src="../assets/Microfinance/Microfinance8.png" />
            </div>
            <div class="Main_left" v-if="Type == 19">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    手游项目专门为手游提供相关交易服务的电子商务平台。独创的CBC交易模式，交易安全快捷。随时提供专业、便捷、安全的游戏交易服务;除了虚拟交易外，实物交易并茂发展。
                    平台特色:B2C+C2C是其有别于其他平台的主要特色，经营着行业传统业务;像一波流这样表面以不同的商业模式，实质上凭借创新和尊重客户取得迅猛发展的平台，将是行业未来和方向的引领者。
                </div>
                <img class="Main_left_image" src="../assets/ornaments/ornaments2.jpg" />
                <img class="Main_left_image" src="../assets/ornaments/ornaments3.jpg" />
            </div>
            <div class="Main_left" v-if="Type == 20">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    二手交易是一个采用买卖双方自由定价交易模式的二手商品交易平台，各取所需，天下大同"是我们的理念。所有卖家发布的商品分类成列于自价区、速降区及无价区，旨在解决二手商品“沉睡”问题
                </div>
                <img class="Main_left_image" src="../assets/Encounter/Encounter1.jpg" />
            </div>
            <div class="Main_left" v-if="Type == 21">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    校友服务是一款校园+校外社交的软件，是对人人网APP的重新规划。在功能上规划了很多社交/交流功能，结合了小视频、推荐、话题、社团、圈子等等功能。不仅能让毕业的校友在闲友上找到曾今的校友，而且可以让在校大学生在平台上加入各种社团
                </div>
                <img class="Main_left_image" src="../assets/Idlefriends/Idlefriends4.jpg" />
                <img class="Main_left_image" src="../assets/Idlefriends/Idlefriends5.jpg" />
                <img class="Main_left_image" src="../assets/Idlefriends/Idlefriends6.jpg" />
                <img class="Main_left_image" src="../assets/Idlefriends/Idlefriends7.jpg" />
                <img class="Main_left_image" src="../assets/Idlefriends/Idlefriends8.jpg" />
                <img class="Main_left_image" src="../assets/Idlefriends/Idlefriends9.jpg" />
                <img class="Main_left_image" src="../assets/Idlefriends/Idlefriends10.jpg" />
                <img class="Main_left_image" src="../assets/Idlefriends/Idlefriends11.jpg" />
                <img class="Main_left_image" src="../assets/Idlefriends/Idlefriends12.jpg" />
                <img class="Main_left_image" src="../assets/Idlefriends/Idlefriends13.jpg" />
                <img class="Main_left_image" src="../assets/Idlefriends/Idlefriends14.jpg" />
                <img class="Main_left_image" src="../assets/Idlefriends/Idlefriends15.jpg" />
                <img class="Main_left_image" src="../assets/Idlefriends/Idlefriends16.jpg" />
            </div>
            <div class="Main_left" v-if="Type == 22">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    购物app是一款线上一站式购物app。不管是日用百货、鞋包服装，还是手机电脑、家电空调等等，都能在这款app上面自由选购，购物app为用户挑选出本地的优质商家，方便用户线上一站式购买商品，服务到位。
                </div>
                <img class="Main_left_image" src="../assets/Yafu/Yafu2.gif" />
                <img class="Main_left_image" src="../assets/Yafu/Yafu3.gif" />
                <img class="Main_left_image" src="../assets/Yafu/Yafu4.gif" />
            </div>
            <div class="Main_left" v-if="Type == 23">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    医疗器械项目是一款专业的电商网购平台，商品以手机数码类为主。平台商品类目齐全，几乎覆盖了市面所有品牌，平台的每一件商品都经过专业的采购人员精心挑选，保证用户享受到优惠的价格和高品质的服务。
                </div>
                <img class="Main_left_image" src="../assets/Medical/Medical1.jpg" />
                <img class="Main_left_image" src="../assets/Medical/Medical2.jpg" />
                <img class="Main_left_image" src="../assets/Medical/Medical3.jpg" />
                <img class="Main_left_image" src="../assets/Medical/Medical4.jpg" />
            </div>
            <div class="Main_left" v-if="Type == 24">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    该系统拥有完善的会员中心，帮助企业精准管理客户信息，实现个性化服务。自动化营销功能可根据预设规则自动执行营销任务，大大提高营销效率。店铺会员模块让企业更好地与会员互动，提升会员忠诚度。消息中心确保企业与客户之间的沟通畅通无阻，及时传递重要信息。活动中心助力企业策划和执行各类营销活动，吸引更多客户参与。组织中心则为企业提供高效的团队管理功能，确保营销工作有序进行。
                </div>
                <img class="Main_left_image" src="../assets/marketing/marketing2.png" />
                <img class="Main_left_image" src="../assets/marketing/marketing4.png" />
                <img class="Main_left_image" src="../assets/marketing/marketing5.png" />
                <img class="Main_left_image" src="../assets/marketing/marketing6.png" />
                <img class="Main_left_image" src="../assets/marketing/marketing7.png" />
                <img class="Main_left_image" src="../assets/marketing/marketing8.png" />
            </div>
            <div class="Main_left" v-if="Type == 25">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    手机数码是一款专业的电商网购平台，商品以手机数码类为主。平台商品类目齐全，几乎覆盖了市面所有品牌，平台的每一件商品都经过专业的采购人员精心挑选，保证用户享受到优惠的价格和高品质的服务
                </div>
                <img class="Main_left_image" src="../assets/Zhongtian/Zhongtian2.gif" />
                <img class="Main_left_image" src="../assets/Zhongtian/Zhongtian3.gif" />
                <img class="Main_left_image" src="../assets/Zhongtian/Zhongtian4.gif" />
            </div>
            <div class="Main_left" v-if="Type == 26">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    政府音乐官网是由政府主导建设的专业音乐平台，旨在弘扬优秀音乐文化、推动音乐艺术发展、丰富民众精神生活。它以传播积极健康的音乐作品为核心任务，为广大音乐爱好者、创作者以及音乐产业相关人士提供全方位的服务和支持
                </div>
                <img class="Main_left_image" src="../assets/Music/cover.png" />
            </div>
            <div class="Main_left" v-if="Type == 27">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    本系统旨在为企业和用户提供全方位的活动参与体验，涵盖线下参会报名与核销、线上云直播观看以及便捷的后台管理功能，满足不同场景下的活动需求
                </div>  
                <img class="Main_left_image" src="../assets/meeting/meeting1.png" />
            </div>
            <div class="Main_left" v-if="Type == 28">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    本项目旨在打造一个专为企业服务的综合性商务平台，通过整合资源、拓展商机、促进人脉交流以及举办各类活动，助力企业实现高效发展与合作
                </div>  
                <img class="Main_left_image" src="../assets/Enterprise/Enterprise1.png" />
            </div>
            <div class="Main_left" v-if="Type == 29">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    本系统主要用于学生综合信息管理和奖学金线上申报及审核业务；系统的业务处理功能包括基础信息管理、国际学生信息管理、日常教学管理、奖学金申报和审核管理、黑名单管理等
                </div> 
                <img class="Main_left_image" src="../assets/liuxuesheng/2.png" />
                <img class="Main_left_image" src="../assets/liuxuesheng/3.png" />
            </div>
            <div class="Main_left" v-if="Type == 30">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    这款小程序以用户需求为核心，汇聚了顶尖的设计师团队和优质的材料供应商。无论您是喜欢现代简约风、欧式奢华风还是中式古典风，我们都能为您量身打造独一无二的家居空间。
                    通过直观的界面展示，您可以轻松浏览各种风格的全屋定制案例，获取灵感。同时，利用先进的 3D 可视化技术，您能提前预览定制后的家居效果，让您的想象变为现实。
                    便捷的在线沟通功能，让您随时与设计师交流想法，确保每一个细节都符合您的心意。从家具的款式、尺寸到材质的选择，一切尽在您的掌控之中。
                </div> 
                <img class="Main_left_image" src="../assets/crown/icon_1.png" />
                <img class="Main_left_image" src="../assets/crown/icon_2.png" />
                <img class="Main_left_image" src="../assets/crown/icon_3.png" />
            </div>
            <div class="Main_left" v-if="Type == 31">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    课程管理平台是一种基于互联网技术的软件系统，旨在帮助教育机构或企业高效地管理和组织课程活动。它集成了多种功能模块，包括课程管理、在线学习、考试评估等，以实现课程的规范化、系统化和智能化
                </div> 
                <img class="Main_left_image" src="../assets/curriculum/icon1.jpg" />
                <img class="Main_left_image" src="../assets/curriculum/icon2.jpg" />
            </div>
            <div class="Main_left" v-if="Type == 32">
                <div class="Main_left_text" v-if="name == '有省科技（广东）有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类网站开发，小程序开发，APP开发等技术成熟，经验丰富。聚焦金融、家具、生鲜、美容、电商、母婴等领域。</div>
                <div class="Main_left_text" v-if="name == '柚米（广东）互联网科技有限公司'">我们拥有丰富的成功案例和行业经验，为您提供各类软件定制开发，深耕企业内部应用软件，主要有ERP、SCM、MES等</div>
                <div class="Main_left_text" v-if="name == '宁乡市昭辰信息咨询服务部'">可接项目，从事软件开发行业，可独立完成项目的开发，曾开发过社交、理财、电商等项目。</div>
                <div class="Main_left_text" v-if="name == '广州骅穗教育咨询有限公司'">骅穗咨询致力于培训平台以及课程研发，培养专业研发和师资队伍，输出专业课程，整合各类培训资源。</div>
                <div class="Main_left_text" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">团队包含各端资深开发人员，项目经验丰富，提供0-1的定制服务</div>
                <div class="Main_left_text">
                    课程管理平台是一种基于互联网技术的软件系统，旨在帮助教育机构或企业高效地管理和组织课程活动。它集成了多种功能模块，包括课程管理、在线学习、考试评估等，以实现课程的规范化、系统化和智能化
                </div> 
                <img class="Main_left_image" src="../assets/train/1.png" />
                <img class="Main_left_image" src="../assets/train/2.png" />
            </div>
            <div class="Main_right">
                <div class="Main_right_title">发布用户:{{name}}</div>
                <div class="Main_right_phone" v-if="name == '有省科技（广东）有限公司'">联系方式:180******20</div>
                <div class="Main_right_phone" v-if="name == '柚米（广东）互联网科技有限公司'">联系方式:400******82</div>
                <div class="Main_right_phone" v-if="name == '宁乡市昭辰信息咨询服务部'">联系方式:147******58</div>
                <div class="Main_right_phone" v-if="name == '广州骅穗教育咨询有限公司'">联系方式:166******89</div>
                <div class="Main_right_phone" v-if="name == '广州市天河区沙东凯仕信息技术服务部'">联系方式:180******11</div>

                <div class="Main_right_url" @click="GogoodsDetails">查看此用户的主页</div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted } from 'vue'
import { useRouter ,useRoute } from 'vue-router'
export default {
    setup(){
        onMounted(() => {
            let name = ''
            if(route.params.id == 1){
                name = '贷款项目'
            }else if(route.params.id == 2){
                name = '家具项目'
            }else if(route.params.id == 3){
                name = '销售平台'
            }else if(route.params.id == 4){
                name = '充电桩查询'
            }else if(route.params.id == 5){
                name = '服装ERP管理系统'
            }else if(route.params.id == 6){
                name = '餐饮行业官网'
            }else if(route.params.id == 7){
                name = '采购平台'
            }else if(route.params.id == 8){
                name = '生鲜平台'
            }else if(route.params.id == 9){
                name = '全屋定制家具'
            }else if(route.params.id == 10){
                name = '美容行业平台'
            }else if(route.params.id == 11){
                name = '家居生活'
            }else if(route.params.id == 12){
                name = '选房平台'
            }else if(route.params.id == 13){
                name = '电商带货'
            }else if(route.params.id == 14){
                name = '母婴平台'
            }else if(route.params.id == 15){
                name = '木材货源'
            }else if(route.params.id == 16){
                name = '社交平台'
            }else if(route.params.id == 17){
                name = '微宠平台'
            }else if(route.params.id == 18){
                name = '理财项目'
            }else if(route.params.id == 19){
                name = '手游项目'
            }else if(route.params.id == 20){
                name = '二手交易'
            }else if(route.params.id == 21){
                name = '校友服务'
            }else if(route.params.id == 22){
                name = '购物app'
            }else if(route.params.id == 23){
                name = '医疗器械项目'
            }else if(route.params.id == 24){
                name = '营销管理系统'
            }else if(route.params.id == 25){
                name = '手机数码'
            }else if(route.params.id == 26){
                name = '音乐平台'
            }else if(route.params.id == 27){
                name = '会议系统'
            }else if(route.params.id == 28){
                name = '人脉项目'
            }else if(route.params.id == 29){
                name = '学生管理'
            }else if(route.params.id == 30){
                name = '设计项目'
            }
            document.title = name
        })

        const router = useRouter();
        const route = useRoute()

        let Type = route.params.id
        let name = route.params.name

        function GogoodsDetails(){
            router.push({
                name:'UserPage',
                params:{
                    id:Type,
                    name:name
                }
            })
        }

        return{
            GogoodsDetails,
            Type,
            name
        }
    }
}
</script>

<style scoped>
.Info{
    width: 1200px;
    margin: 0 auto;
}
.TopBox{
    margin: 20px auto auto auto;
    display: flex;
    align-items: center;
    background: #fff;
    border-bottom: 1px solid #ccc;
    padding: 20px 0;
}
.TopBox_image{
    width: 100px;
    height: 100px;
    margin-left: 20px;
}
.TopBox_right{
    margin-left: 20px;
}
.TopBox_right_title{
    font-size: 20px;
    font-weight: bold;
}
.TopBox_right_laber{
    display: flex;
    align-items: center;
    margin-top: 5px;
}
.TopBox_right_laber_name{
    font-size: 10px;
    color: #ccc;
}
.TopBox_right_laber_main{
    padding: 2px 10px;
    font-size: 10px;
    border-radius: 5px;
    color: #ccc;
    margin-left: 20px;
    border:1px solid #d4cccc
}
.TopBox_right_address{
    margin-top: 10px;
    font-size: 10px;
    color: #ccc;
}

.Main{
    display: flex;
    margin-top: 20px;
}
.Main_left{
    flex: 1;
    background: #fff;
    padding: 10px;
}
.Main_left_image{
    width: 100%;
    display: block;
}
.Main_right{
    width: 400px;
    margin-left: 10px;
    height: fit-content;
    background: #fff;
    padding: 10px;
}
.Main_right_title{
    font-size: 10px;
}
.Main_right_phone{
    font-size: 10px;
    padding: 20px 0px;
    border-bottom: 1px solid #ccc;
}
.Main_right_url{
    text-align: center;
    color: rgb(30, 148, 255);
    text-align: center;
    font-size: 10px;
    margin-top: 10px;
    cursor: pointer;
}
.Main_left_text{
    font-size: 15px;
    margin-bottom: 10px;
}
.Main_left_text_name{
    color: #666666;
    font-size: 15px;
    margin-bottom: 10px;
}
.Main_left_text_content{
    font-size: 13px;
}
</style>